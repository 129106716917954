import React from 'react'
import HeroSection from '../components/HeroSection'
import SubHero from '../components/SubHero'

import TechnologySection from '../components/TechnologySection'
import ServicesSection from '../components/ServicesSection'
import IntroSection from '../components/IntroSection'

import NavBar from "../components/NavBar"
import Contact from "../components/Contact"
import SimpleFooter from "../components/SimpleFooter"
import CredationSection from "../components/CredationSection"
import TeamSection from "../components/OurTeamSection"
import AdvisoryBoard from "../components/AboutSection"
import NewsSection from "../components/NewsSection"
import NewHeroSection from '../components/NewHeroSection'

class Home extends React.Component {
	render() {
		return (
			<>
				<NavBar />
				{/* <HeroSection
					first="Finding diagnoses, Finding cures"
					second={<>Comprehensive Genetic Testing <br></br> + <br></br> Counseling Services</>}
					third="Maximum sensitivity and specificity through cutting-edge technologies and methodologies" 
					fourth="High complexity genome testing made accessible"
				/> */}
				<NewHeroSection 
					first="Finding diagnoses, Finding cures"
					second={<>Comprehensive Genetic <br></br> Testing & Consulation</>}
					third="Despite costly and extensive testing, nearly 50% of patients with an underlying genetic disease never receive a molecular diagnosis. Even with breakthroughs that tailor each patient's treatment to their cancer's unique genetic profile, fewer than 10% of cancer patients undergo such testing and therefore do not have access to these new treatments." 
					fourth="We created Praxis Genomics to overcome these limitations."
					fifth="Praxis Genomics is the only provider in the world to combine Bionano Optical Genome Mapping technology with Whole Genome and Transcriptome Sequencing. This investigates the genome at the sequence, structure, and function levels simultaneously and grants patients access to the most comprehensive genetic testing clinically available to date."
					sixth="With this unique approach, Praxis Genomics provides unprecedented depth of cancer profiling and achieves an unsurpassed diagnosis rate for otherwise unsolved genetic disorders."
				/>
				<SubHero />
				<ServicesSection />
				<TeamSection />
				<AdvisoryBoard />
				<NewsSection />
				<TechnologySection />
				<Contact />
				<CredationSection />
				<SimpleFooter />
			</>
		)
	}
}

export default Home
